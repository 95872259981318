.bg-gradient {
  background: rgb(76, 33, 104);
  background: linear-gradient(
    90deg,
    rgba(76, 33, 104, 1) 0%,
    rgba(33, 37, 104, 1) 100%
  );
}

.bg-gradient-red {
  background: rgb(253, 29, 29);
  background: linear-gradient(
    90deg,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 100%
  );
}

.btn-custom {
  color: #fff !important;
  border: none;
}

.btn-custom:focus,
.btn-custom:hover {
  color: #fff !important;
}

.pageActive {
  display: block !important;
}

.pageInactive {
  display: none !important;
}

.questionTitle {
  color: #4c2168;
  padding: 20px 10px;
}

.btn-selected {
  background: #4c2168 !important;
  color: #fff !important;
}

.btn-primary {
  background-color: #dedede;
  border-color: #dedede;
  color: #000;
}

.btn-primary:hover,
.btn-primary:focus {
  background: #dedede;
  border-color: #dedede;
}

.containerRadio {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.containerRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ccc;
  border-radius: 50%;
}

.checkmark.checkbox {
  border-radius: 5px !important;
}

/* On mouse-over, add a grey background color */
.containerRadio:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.containerRadio input:checked ~ .checkmark {
  background-color: #4c2168;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerRadio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerRadio .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.form-row input {
  width: 49%;
  display: inline-block;
}

.form-row input:first-child {
  margin-right: 1%;
}

.radioHorizontal {
  display: inline-block !important;
}

.containerRadioHorizontalWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.indicatorTitle {
  font-size: 15px;
  margin-bottom: 10px;
  display: block;
}

label.label_checkbox {
  display: block;
  padding-left: 15px;
  text-indent: -15px;
}
input.checkbox_custom {
  width: 13px;
  height: 13px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  position: relative;
  top: -7px;
  zoom: 1.5;
  *overflow: hidden;
}

